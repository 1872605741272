import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import Image from 'next/image';

import css from './Select.module.scss';

export type TUiSelectOption = {
  id: number;
  name: string;
};

interface IProps {
  defaultOptionId: number;
  options: TUiSelectOption[];
  onChange: (opt: TUiSelectOption) => void;
}

export function UiSelect({ defaultOptionId, options, onChange }: IProps) {
  const [selectedOption, setSelectedOption] = useState<TUiSelectOption | null>(
    null
  );
  const [isOpened, setIsOpened] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpened(!isOpened);
  };

  const selectOption = (opt: TUiSelectOption) => {
    setSelectedOption(opt);
    setIsOpened(false);
    onChange(opt);
  };

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(options.find((opt) => opt.id === defaultOptionId));
    }
  }, [selectedOption, defaultOptionId, options]);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        isOpened &&
        selectRef.current &&
        !selectRef.current.contains(e.target)
      ) {
        setIsOpened(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpened, selectRef]);

  return (
    <div className={cn(css.block, isOpened && css.opened)} ref={selectRef}>
      <div className={css.current} aria-hidden onClick={toggleOpen}>
        {selectedOption && (
          <span className={css.currentLabel}>{selectedOption.name}</span>
        )}
        <Image
          className={css.arrow}
          src="/icons/select-arrow.svg"
          width="10px"
          height="18px"
          alt=""
        />
      </div>
      <ul className={css.list}>
        {options.map((opt) => (
          <li
            className={cn(
              css.listItem,
              selectedOption &&
                opt.id === selectedOption.id &&
                css.listItemSelected
            )}
            key={opt.id}
            aria-hidden
            onClick={() => {
              selectOption(opt);
            }}
          >
            {opt.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
