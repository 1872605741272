import { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import cn from 'classnames';

import css from './Cover.module.scss';

interface IProps {
  size?: 'small' | 'medium' | 'large' | 'intro';
  className?: string;
  background: string;
  vertical?: 'top' | 'center' | 'bottom';
  type?: 'image' | 'video' | 'broadcast';
  link?: string;
  children?: ReactNode;
  onClick?(): void;
}

interface UiContentProps {
  widget?: ReactNode;
  children: ReactNode;
  fullHeight?: boolean;
}

interface ITopProps {
  widgets?: {
    left?: ReactNode;
    right?: ReactNode;
  };
}

const UiCover = (props: IProps) => {
  const {
    size,
    className,
    vertical,
    type,
    link,
    children,
    onClick,
    background,
  } = props;

  const wrapperClasses = cn(css.wrapper, size && css[size], css[vertical]);

  const styles: any = {};

  const classes = cn(css.container, className);

  return (
    <div className={cn(wrapperClasses, { [css.clicked]: !!onClick })}>
      {link ? (
        <Link href={link}>
          <a className={classes} style={styles}>
            <div className={css.children}>{children}</div>
            <UiCoverBackground type={type} background={background} />
          </a>
        </Link>
      ) : (
        <a
          role="button"
          className={classes}
          style={styles}
          onClick={onClick}
          onKeyDown={onClick}
        >
          <div className={css.children}>{children}</div>
          <UiCoverBackground type={type} background={background} />
        </a>
      )}
    </div>
  );
};

UiCover.defaultProps = {
  size: 'medium',
  className: '',
  vertical: 'bottom',
  type: 'image',
  link: null,
  onClick: null,
  children: null,
};

const UiCoverBackground = ({ type, background }: any) => {
  return (
    <div className={css.background}>
      {background && (
        <>
          {type === 'image' && (
            <Image
              src={background}
              layout="fill"
              objectFit="cover"
              quality="100"
              alt=""
            />
          )}
          {type === 'video' && (
            <video
              id="video"
              width="100%"
              height="auto"
              autoPlay
              loop
              preload="auto"
              muted
              playsInline
            >
              <source src={background} />
            </video>
          )}
          {type === 'broadcast' && (
            <iframe
              className={css.broadcast}
              src={background}
              title=""
              frameBorder="0"
              allowFullScreen
            />
          )}
        </>
      )}
    </div>
  );
};

const UiCoverTop = ({ widgets }: ITopProps) => {
  return (
    <ul className={css.topWidgets}>
      <li>{widgets?.left}</li>
      <li>{widgets?.right}</li>
    </ul>
  );
};

UiCoverTop.defaultProps = {
  widgets: null,
};

const UiCoverContent = ({ widget, children, fullHeight }: UiContentProps) => {
  const classes = cn(
    css.content,
    !widget && css.withoutWidget,
    fullHeight && css.fullHeight
  );

  return (
    <div className={classes}>
      {children}
      {widget && <div className={css.right}>{widget}</div>}
    </div>
  );
};

UiCoverContent.defaultProps = {
  widget: null,
  fullHeight: false,
};

const UiCoverButtons = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  const classes = cn(css.buttons, className);

  return (
    <div className={classes}>
      <div className={css.buttonsInner}>{children}</div>
    </div>
  );
};

UiCoverButtons.defaultProps = {
  className: '',
};

const UiCoverTitle = ({ children }: { children: string | ReactNode }) => (
  <div className={css.title}>{children}</div>
);

const UiCoverDescription = ({ children }: { children: string | ReactNode }) => (
  <div className={css.description}>{children}</div>
);

UiCover.Top = UiCoverTop;
UiCover.Content = UiCoverContent;
UiCover.Buttons = UiCoverButtons;
UiCover.Title = UiCoverTitle;
UiCover.Description = UiCoverDescription;

export { UiCover };
