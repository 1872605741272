import { createElement } from 'react';
import cn from 'classnames';

import css from './Button.module.scss';

export interface IUiButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'outline';
  size?: 'small' | 'medium';
  fluid?: boolean;
  href?: string;
  asTag?: string;
  bgColor?: string;
  textColor?: string;
  altTextColor?: string;
  target?: string;
  className?: string;
}

function UiButton({
  children,
  variant,
  asTag,
  size,
  fluid,
  bgColor,
  textColor,
  altTextColor,
  href,
  className,
  ...rest
}: IUiButtonProps) {
  const classes = cn(
    className,
    css.block,
    css[variant as string],
    fluid && css.fluid,
    size && css[size]
  );
  const tag = href ? 'a' : asTag;

  const props = {
    type: tag === 'button' ? 'button' : undefined,
    href: href || undefined,
    className: classes,
    style: {
      backgroundColor: variant === 'primary' ? bgColor : undefined,
      borderColor: variant === 'outline' ? bgColor : undefined,
      color: variant === 'primary' ? textColor : altTextColor,
    },
    ...rest,
  };

  return createElement(tag, props, children);
}

UiButton.defaultProps = {
  variant: 'primary',
  size: 'medium',
  asTag: 'button',
  fluid: false,
  href: undefined,
  target: undefined,
  bgColor: '#ccccccc',
  textColor: '#ffffff',
  altTextColor: '#000000',
};

export { UiButton };
