import { ReactNode } from 'react';
import classNames from 'classnames';

import getElementType from 'utils/getElementType';
import TextWrapper from './TextWrapper';

import css from './Text.module.scss';

interface IProps {
  children: ReactNode | JSX.Element | string;
  as?: string;
  align?: 'left' | 'center' | 'right';
  size?: 'small_x' | 'small' | 'small_plus' | 'normal' | 'large';
  color?: 'blue' | 'danger' | 'white';
  weight?: 'light' | 'normal' | 'bold';
  href?: string;
  className?: string;
  onClick?: (e?: any) => void;
}

export const UiText = (props: IProps) => {
  const { children, as, align, size, weight, className, color, ...rest } =
    props;
  const ElementType = getElementType(UiText, props);

  const classes = classNames(
    className,
    css.text,
    align && css[align],
    size && css[size],
    weight && css[weight],
    as && css[as],
    color && css[color]
  );

  return (
    <ElementType className={classes} {...rest}>
      {children}
    </ElementType>
  );
};

UiText.defaultProps = {
  as: 'div',
  align: 'left',
  size: 'normal',
};

UiText.Wrapper = TextWrapper;
