import { useState } from 'react';
import { UiText } from './';

interface IProps {
  size?: number;
  children: string;
}

const TextWrapper = ({ size, children }: IProps) => {
  let [open, setOpen] = useState<boolean>(false);

  const handleMore = () => {
    setOpen((open: boolean) => !open);
  };

  if (!size || children.length <= size) {
    return <div>{children}</div>;
  }

  return (
    <div>
      {open ? (
        <span>
          {children + ' '}
          <UiText as="a" onClick={handleMore}>
            скрыть
          </UiText>
        </span>
      ) : (
        <span>
          {children.slice(0, size) + '... '}
          <UiText as="a" onClick={handleMore}>
            еще
          </UiText>
        </span>
      )}
    </div>
  );
};
export default TextWrapper;
