import { ReactNode, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { useOnClickOutside } from 'hooks/utils/useClickOutside';

import css from './Modal.module.scss';

interface IProps {
  children: ReactNode;
  isShowing: boolean;
  onClose?(): void;
  className?: string;
  closeClassName?: string;
}
const ESC_BTN_CODE = 'Escape';

export const UiModal = ({
  isShowing,
  children,
  onClose,
  className,
  closeClassName,
}: IProps) => {
  const modalBodyEl = useRef<HTMLDivElement>(null);
  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === ESC_BTN_CODE && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  useOnClickOutside(modalBodyEl, () => {
    onClose && onClose();
  });

  useEffect(() => {
    if (isShowing) {
      document.addEventListener('keyup', keyboardListener);
      document.querySelector('body')?.classList.add('no-scroll');
    }
    return () => {
      if (isShowing) {
        document.removeEventListener('keyup', keyboardListener);
        document.querySelector('body')?.classList.remove('no-scroll');
      }
    };
  }, [isShowing, keyboardListener]);

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={css.wrapper}>
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              className={cn(css.body, className)}
              ref={modalBodyEl}
            >
              <button
                type="button"
                className={cn(css.close, closeClassName)}
                onClick={onClose}
              >
                ✕
              </button>
              {children}
            </motion.div>
          </div>
        </>,
        document.body
      )
    : null;
};
