import Slider, { Settings } from 'react-slick';

import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import css from './Slider.module.scss';

interface IProps extends Settings {
  className?: string;
  children: React.ReactNode;
}

const UiSlider = (props: IProps) => {
  const { className, children } = props;

  return (
    <Slider {...props} className={cn(className, css.block)}>
      {children}
    </Slider>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      type="button"
      className={cn(css.slickNext, className)}
      style={{ ...style }}
      onClick={onClick}
    >
      Previous
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <button
      type="button"
      className={cn(css.slickPrev, className)}
      style={{ ...style }}
      onClick={onClick}
    >
      Next
    </button>
  );
};

UiSlider.NextArrow = NextArrow;
UiSlider.PrevArrow = PrevArrow;

export { UiSlider };
