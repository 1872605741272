import React, { SVGProps } from 'react';

export interface IProps extends SVGProps<SVGElement> {
  name: string;
  size?: number;
}

export const UiIcon = ({
  name,
  size = 24,
  width,
  height,
  ...props
}: IProps) => {
  const Icon = require(`./icons/${name}.svg`).default;
  const w = width || size;
  const h = height || size;

  console.log('Icon: ', Icon);

  return <Icon width={w} height={h} {...props} />;
};
