import { HTMLAttributes } from 'react';
import css from './Checkbox.module.scss';

interface IProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  checked?: boolean;
  children: React.ReactNode;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

const UiCheckbox = (props: IProps) => {
  const { id, checked, children, ...rest } = props;

  return (
    <div>
      <input
        className={css.input}
        id={id}
        type="checkbox"
        name={id}
        {...checked}
        {...rest}
      />
      <label htmlFor={id} className={css.label}>
        <div className={css.icon}>
          <svg width="8" height="6" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.7 0.3C7.3 -0.1 6.7 -0.1 6.3 0.3L3 3.6L1.7 2.3C1.3 1.9 0.7 1.9 0.3 2.3C-0.1 2.7 -0.1 3.3 0.3 3.7L2.3 5.7C2.5 5.9 2.7 6 3 6C3.3 6 3.5 5.9 3.7 5.7L7.7 1.7C8.1 1.3 8.1 0.7 7.7 0.3V0.3Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={css.text}>{children}</div>
      </label>
    </div>
  );
};

UiCheckbox.defaultProps = {
  checked: false,
};

export { UiCheckbox };
