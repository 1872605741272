interface IProps {
  as?: string;
  href?: string;
}
const getElementType = (Component: any, props: IProps) => {
  const { defaultProps = {} } = Component;

  if (props.as && props.as !== defaultProps.as) return props.as;

  if (props.href) return 'a';

  return defaultProps.as || 'div';
};

export default getElementType;
