import { useState } from 'react';
import cn from 'classnames';

import css from './Tags.module.scss';

type TTagItem = {
  label: string | React.ReactNode;
  value: string;
};

interface IProps {
  tags: TTagItem[];
  selected?: string | string[];
  multiple?: boolean;
  vertical?: boolean;
  onTagClick?(tags: string | string[]): void;
  className?: string;
  classNameActive?: string;
}

export const UiTags = (props: IProps) => {
  const {
    tags,
    selected,
    multiple,
    vertical,
    onTagClick,
    className,
    classNameActive,
  } = props;

  const [selectedTags, setSelectedTags] = useState(
    selected || (multiple ? [] : '')
  );

  const isSelectedTag = (tag: TTagItem) =>
    multiple ? selectedTags?.includes(tag.value) : selectedTags === tag.value;

  const handleTagClick = (tag: TTagItem) => {
    let newTags: string | string[];

    if (multiple) {
      if (
        typeof selectedTags === 'object' &&
        selectedTags.includes(tag.value)
      ) {
        newTags = selectedTags.filter((t) => t !== tag.value);
      } else {
        newTags = [tag.value].concat(selectedTags);
      }
    } else {
      newTags = tag.value;
    }

    setSelectedTags(newTags);
    if (onTagClick) {
      onTagClick(newTags);
    }
  };

  return (
    <ul className={cn(css.list, className, vertical && css.vertical)}>
      {tags.map((tag, index) => (
        <li
          className={cn(
            css.listItem,
            isSelectedTag(tag) && (classNameActive || css.active)
          )}
          key={index}
          onClick={() => handleTagClick(tag)}
        >
          {tag.label}
        </li>
      ))}
    </ul>
  );
};
