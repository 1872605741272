import { KeyboardEvent, useState } from 'react';
import cn from 'classnames';

import { UiIcon } from '../Icon';
import { UiText } from '../Text';

import css from './Input.module.scss';

const ENTER_BTN_CODE = 'Enter';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorLabel?: string;
  type?: string;
  fluid?: boolean;
  onEnterKey?(): void;
}

export const UiInput = ({
  label,
  errorLabel,
  type = 'text',
  fluid,
  onEnterKey,
  ...rest
}: IProps) => {
  const [inputType, setInputType] = useState(type);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === ENTER_BTN_CODE && onEnterKey) {
      onEnterKey();
    }
  };

  const handleEyeClick = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <div className={css.block}>
      {label && (
        <UiText className={css.label} as="label" size="small_plus">
          {label}
        </UiText>
      )}
      <div className={css.field}>
        <input
          {...rest}
          className={cn(css.input, fluid && css.fluid, errorLabel && css.error)}
          type={inputType}
          onKeyUp={handleKeyUp}
        />
        {type === 'password' && (
          <div
            className={cn(css.eye, inputType === 'text' && css.eyeClosed)}
            onClick={handleEyeClick}
          >
            <UiIcon
              name={inputType === 'password' ? 'eye' : 'eye-close'}
              width={16}
              height={inputType === 'password' ? 10 : 16}
              color="#d7dadf"
            />
          </div>
        )}
      </div>
      {errorLabel && (
        <UiText size="small" color="danger">
          {errorLabel}
        </UiText>
      )}
    </div>
  );
};
